import React, { Suspense, lazy } from 'react';
import { ImMap } from "react-icons/im";
import globe from "../assets/images/globe_overview.webp";
import AdvManuLogo from "../assets/svgs/pptx_advancedmanu_logo.svg";
import CyberSecurityLogo from "../assets/svgs/pptx_cybersec_logo.svg";
import ArchEngLogo from "../assets/svgs/pptx_archeng_logo.svg"
import DigitalHealthLogo from "../assets/svgs/pptx_digitalhealth_logo.svg";
import FinServLogo from "../assets/svgs/pptx_financeinsur_logo.svg";
import HumanLifeLogo from "../assets/svgs/pptx_lifesci_logo.svg";
import PlantAnimalLogo from "../assets/svgs/pptx_plantanimal_logo.svg";
import TechLogo from "../assets/svgs/pptx_tech_logo.svg";
import MeetingStock from "../assets/images/meeting-stock.webp";
import EnterpriseGlobe from "../assets/svgs/enterprise_globe.svg";
import GlobalSearch from "../assets/svgs/global-search.svg";
import Rocket from "../assets/svgs/rocket.svg";
import Speed from "../assets/svgs/speed.svg";
import Users from "../assets/svgs/users.svg";
import backgroundImage from '../assets/images/landing-page-bg-2.webp';
import mobileBackgroundImage from '../assets/images/landing-page-bg-small.webp';
import { IoPeopleOutline, IoShieldCheckmarkOutline } from 'react-icons/io5';
import { GoLightBulb } from 'react-icons/go';
import ReportPlaceholder from "../assets/images/placeholder_report.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from 'react-router-dom';
import HeroText from '../components/HeroText';
import LandingHeader from '../components/LandingHeader';
import ParallaxHeader from "../components/ParallaxHeader";
import RegularFade from '../components/animations/RegularFade';
import useIsMobile from '../hooks/useIsMobile';
import BlackHeader from '../components/BlackHeader';

// Lazy load components
const LayeredPanel = lazy(() => import('../components/LayeredPanel'));
const SectionSlant = lazy(() => import('../components/SectionSlant'));
const SignUp = lazy(() => import('../components/SignUp'));
const SimpleCardContainer = lazy(() => import('../components/SimpleCardContainer'));
const FactGrid = lazy(() => import('../components/FactGrid'));

const facts = [
    { fact: "Access job listings from all 50 U.S. states.", imageSrc: EnterpriseGlobe },
    { fact: "Efficiently search jobs organized by clusters and SOC codes.", imageSrc: GlobalSearch },
    { fact: "Receive real-time updates on the latest job openings.", imageSrc: Rocket },
    { fact: "Enhance your career with personalized resources and tools.", imageSrc: Users },
    { fact: "Join a platform committed to your professional growth.", imageSrc: Speed },
];

const cards = [
    {
        image: AdvManuLogo,
        title: "Advanced Manufacturing",
        count: 256
    },
    {
        image: ArchEngLogo,
        title: "Architecture & Engineering",
        count: 198
    },
    {
        image: CyberSecurityLogo,
        title: "Cybersecurity",
        count: 143
    },
    {
        image: DigitalHealthLogo,
        title: "Digital Health",
        count: 175
    },
    {
        image: FinServLogo,
        title: "Financial Services & Insurance",
        count: 220
    },
    {
        image: HumanLifeLogo,
        title: "Human Life Sciences",
        count: 160
    },
    {
        image: PlantAnimalLogo,
        title: "Plant & Animal Sciences",
        count: 190
    },
    {
        image: TechLogo,
        title: "Technology",
        count: 300
    },
];

const iconsWithText = [
    { icon: <IoPeopleOutline className='text-4xl' />, text: 'Collaboration' },
    { icon: <GoLightBulb className='text-4xl' />, text: 'Innovation' },
    { icon: <IoShieldCheckmarkOutline className='text-4xl' />, text: 'Trustworthiness' }
];

const Loading: React.FC = () => <div>Loading...</div>;

const LandingPage: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const backgroundImageToUse = isMobile ? mobileBackgroundImage : backgroundImage;

    return (
        <div className='bg-custom-gray'>
            <BlackHeader
                name="Fortisfera"
                motto="Your Gateway to Nationwide Opportunities"
                industry="Job Listings"
            />
            <div className='container'>
                <LandingHeader
                    tagline='Explore Opportunities Across America'
                    title='Find Your Next Career Move with Fortisfera'
                    subtitle="Fortisfera connects you with job listings from every state, helping you discover the perfect opportunity no matter where you are or where you want to be. Browse positions across various industries, tailored to your skills and aspirations."
                    image={MeetingStock}
                />
                <HeroText
                    tagline='Data-Driven, User-Focused'
                    title='Browse Jobs by Clusters and SOC Codes'
                    subtitle="Our platform organizes job listings into specific clusters and SOC code names, making your job search more efficient and precise. Whether you're advancing in your current field or exploring new industries, Fortisfera simplifies your journey."
                    className='mb-8 flex flex-col items-center justify-center text-center'
                />
            </div>
            <Suspense fallback={<Loading />}>
                <div className='container'>
                    <SimpleCardContainer cards={cards} />
                </div>
                <LayeredPanel
                    title="Interactive Job Map"
                    subtitle="Use our interactive map to explore job opportunities across the United States. Filter by state, industry cluster, or SOC code to find positions that align with your career goals."
                    buttonText="Explore Map"
                    onButtonClick={() => navigate('/job-map')}
                    buttonIcon={<ImMap className='text-lg fill-fortisfera-blue' />}
                    image={globe}
                    icons={iconsWithText}
                />
                <SectionSlant
                    tagline="Insights and Resources"
                    title="Empower Your Job Search"
                    subtitle="Access our collection of career resources, including industry insights, resume tips, and interview guides. Fortisfera is committed to supporting you every step of the way towards your next career opportunity."
                    image={<LazyLoadImage src={ReportPlaceholder} alt="Resource Placeholder" threshold={150} className="rounded-lg" />}
                    topColor='from-custom-gray'
                    bottomColor='to-custom-black'
                    buttonText="Access Resources"
                />
                <FactGrid
                    title="Our Mission"
                    subtitle='Discover what drives us.'
                    facts={facts}
                />
                <SignUp />
            </Suspense>
        </div >
    );
}

export default RegularFade(LandingPage);
