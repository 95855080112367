import React from 'react';
import Triple from './TripleLine';
import SplitButton from './SplitButton';
import KsIcon from '../assets/svgs/outline-ks.svg';
import MoIcon from '../assets/svgs/outline-missouri.svg';
import Tagline from './typography/Tagline';


interface BlackHeaderProps {
    name: string;
    motto: string;
    industry: string;
}

const BlackHeader: React.FC<BlackHeaderProps> = ({ name, motto, industry }) => {
    return (
        <div
            className="relative overflow-hidden flex items-center justify-center h-[900px] bg-black font-jakarta"
        >
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black/60"></div>
            <div className="relative z-10 text-center p-4 md:p-8 lg:p-12">
                <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-medium mb-4">{name}</h1>
                <div className='flex justify-center my-8'>
                    <Triple />
                </div>
                <Tagline className='text-white font-normal'>Job Listings Across the United States</Tagline>
                <div className='flex justify-center'>
                </div>
            </div>
        </div>
    );
};

export default BlackHeader;
