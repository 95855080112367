import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import HeroText from '../HeroText';
import { FaFlask } from "react-icons/fa";
import { FaBullseye } from "react-icons/fa6";
import { GrDocumentTest } from "react-icons/gr";
import { GrDocumentConfig } from "react-icons/gr";
import { AiOutlineTeam } from "react-icons/ai";
import { PiUserFocus } from "react-icons/pi";
import { MdOutlineAssessment } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";

const TalentTimeline: React.FC = () => {
    return (
        <div className='!font-jakarta container pb-16 lg:pb-32'>
            <HeroText
                tagline='PAST • PRESENT • FUTURE'
                title='Project Milestones'
                subtitle='Analysis, Design, Development, Testing, Deployment, Operations, Support'
                className='mb-8 flex flex-col items-center justify-center text-center'
            />
            <Timeline position='alternate'>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        May 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaFlask className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Research and Analysis
                        </Typography>
                        <Typography className='!font-jakarta'>Research and analysis of various talent tools in the market today with a focus on the end user experience.</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        June 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <FaBullseye className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Mission and Vision
                        </Typography>
                        <Typography className='!font-jakarta'>Talent Platform Mission and Vision refinement based on market research and industry feedback.</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        August 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <GrDocumentTest className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Market Requirements
                        </Typography>
                        <Typography className='!font-jakarta'>Completion of Market Requirements Document (MRD). The MRD describes the overall market opportunity, the types of customers targeted, and the competitors in the space.</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        September 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                        <TimelineDot className='!bg-ekc-blue'>
                            <GrDocumentConfig className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Product Requirements
                        </Typography>
                        <Typography className='!font-jakarta'>Completion of Product Requirements Document (PRD). The PRD communicates what capabilities and functional requirements are required for the product.</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        October 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <AiOutlineTeam className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Vendor Selection
                        </Typography>
                        <Typography className='!font-jakarta'>Finalized Vendor selection for Talent Platform project. Project Kickoff with key stakeholders, assessment team, and development team.</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        December 2022
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <PiUserFocus className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Focus Groups
                        </Typography>
                        <Typography className='!font-jakarta'>Completion of Round 2 Focus Groups with industry experts to understand high-performance within cybersecurity. Talent Platform Iteration 1 completion with a focus on initial content creation and verbiage/graphics for landing pages.</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        February 2023
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-blue'>
                            <MdOutlineAssessment className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            Assessment Integration
                        </Typography>
                        <Typography className='!font-jakarta'>Review of existing assessment instruments for Assessment Tool integration. Identification of top-priority cluster of attributes based on focus group feedback.</Typography>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align='right'
                        variant='body2'
                        color='text.secondary'
                        className='!font-jakarta'
                    >
                        March 2023
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot className='!bg-ekc-green'>
                            <RiUserFollowLine className='w-6 h-auto' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant='h6' component='span' className='!font-jakarta !font-semibold'>
                            User Experience
                        </Typography>
                        <Typography className='!font-jakarta'>Talent Platform Iteration 2 with a focus on user welcome experience, prioritized attribute scenario development, and media integration.</Typography>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>
    );
}

export default TalentTimeline;